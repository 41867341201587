
import './App.css';
import Test from "./components/Test";
import CodingTest from "./components/CodingTest";

function App() {
  return (
    <>
  <CodingTest/>
    </>
  );
}

export default App;
